(function () {
	angular.module('Plania').controller('SidebarController', ['$scope', 'Repository', '$localStorage', '$rootScope', 'TranslationService', "IconService", 'states', 'CommonService', '$window', controller]);

	function controller($scope, repository, $localStorage, $rootScope, translationService, iconService, states, commonService, $window) {
		$scope.isBBY = $localStorage.generalOptions.IsBBY;
		$scope.guidDataOwner = commonService.getFilterData().selectedDataOwner.Guid;
		$scope.hasImageLogo = !!$localStorage.generalOptions.GuidImageLogo || commonService.getFilterData().selectedDataOwner.GuidImageLogo;
		$scope.mainMenu = [];


		$scope.getIcon = function (icon) {
			if (icon) {
				return iconService.getIcon(icon);
			} else {
				return ("fa-bookmark");
			}
		};

		var updateAccess = function () {
			repository.GetPaginated(repository.apiData.userAccess.url, 0, 0, {}, {}).then(function (response) {
				if (response) {
					response.forEach(function (row) {
						repository.authService.updateUserAccess(row);
					});
				}
				$rootScope.$broadcast($rootScope.events.updatedAccess, true);
				updateMenu();
			});
		};
		updateAccess();

		var canRead = $scope.hasReadAccess;

		var hasModule = {
			activity: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Activity),
			api: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Api),
			area: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Area),
			budget: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Budget),
			cleaningWeb: repository.moduleService.hasModule(repository.moduleService.moduleFlags.CleaningWeb),
			controlList: repository.moduleService.hasModule(repository.moduleService.moduleFlags.ControlList),
			core: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Core),
			dataAcquisition: repository.moduleService.hasModule(repository.moduleService.moduleFlags.DataAcquisition),
			dataImport: repository.moduleService.hasModule(repository.moduleService.moduleFlags.DataImport),
			deviation: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Deviation),
			document: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Document),
			entityLink: repository.moduleService.hasModule(repository.moduleService.moduleFlags.EntityLink),
			housing: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Housing),
			internalRent: repository.moduleService.hasModule(repository.moduleService.moduleFlags.InternalRent),
			inventoryControl: repository.moduleService.hasModule(repository.moduleService.moduleFlags.InventoryControl),
			invoice: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Invoice),
			key: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Key),
			map: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Map),
			projectManagement: repository.moduleService.hasModule(repository.moduleService.moduleFlags.ProjectManagement),
			rent: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Rent),
			sparePart: repository.moduleService.hasModule(repository.moduleService.moduleFlags.SparePart),
			brand: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Brand),
			elhub: repository.moduleService.hasModule(repository.moduleService.moduleFlags.Elhub),
			ifcBimsyncHosting: repository.moduleService.hasModule(repository.moduleService.moduleFlags.IFCBimsyncHosting),
		};

		// Do not change function name, unless the name is changed in plania_translate.
		// Added this to simplify automatically add translation to translation list, since we will use systemName to get in-browser translation config to work.
		var menuItem = function (systemName, defaultName, sref, icon) {
			return {
				index: sref + systemName + defaultName,
				sref: sref,
				systemName: systemName,
				defaultName: defaultName,
				icon: icon,
				title: translationService.translate(systemName, defaultName), // For sorting
				children: []
			};
		};

		var createBasicMenu = function () {
			var menu = menuItem('web-topMenu-basicData', 'Grunndata', null, 'fa-files');
			if (canRead(repository.commonService.prefix.Estate) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-estate', 'Eiendom', 'estate.list'));

			if (canRead(repository.commonService.prefix.Building) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-building', 'Bygg', 'building.list'));

			if (canRead(repository.commonService.prefix.Equipment) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-equipment', 'Anlegg', 'equipment.list'));

			if (canRead(repository.commonService.prefix.Equipment) && hasModule.dataAcquisition)
				menu.children.push(menuItem('web-topMenu-equipment-dataacquisition', 'Datafangst', 'equipment.dataacquisition'));

			if (canRead(repository.commonService.prefix.Drawing) && hasModule.area)
				menu.children.push(menuItem('web-topMenu-drawing', 'Tegninger', 'dwg.list'));

			if (canRead(repository.commonService.prefix.BimFile) && hasModule.ifcBimsyncHosting)
				menu.children.push(menuItem('web-topMenu-bimFile', 'BIM Filer', 'bimFile.list'));

			if (canRead(repository.commonService.prefix.Area) && hasModule.area)
				menu.children.push(menuItem('web-topMenu-area', 'Rom', 'area.list'));

			if (canRead(repository.commonService.prefix.Article) && (hasModule.activity || hasModule.rent || hasModule.internalRent || hasModule.housing))
				menu.children.push(menuItem('web-topMenu-article', 'Artikler', 'article.list'));

			if (canRead(repository.commonService.prefix.Document) && hasModule.document)
				menu.children.push(menuItem('web-topMenu-document', 'Dokumenter', 'document.list'));

			if (canRead(repository.commonService.prefix.Component) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-component', 'Komponenter', 'component.list'));

			if (canRead(repository.commonService.prefix.Customer) && (hasModule.activity || hasModule.area || hasModule.key || hasModule.rent || hasModule.internalRent || hasModule.housing))
				menu.children.push(menuItem('web-topMenu-customer', 'Kunder', 'customer.list'));

			if (canRead(repository.commonService.prefix.Supplier) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-supplier', 'Leverandører', 'supplier.list'));

			if (canRead(repository.commonService.prefix.Person) && hasModule.core)
				menu.children.push(menuItem('web-topMenu-person', 'Personer', 'person.list'));
			return menu;
		};


		var createSparePartMenu = function () {
			var menu = menuItem('web-topMenu-group-sparePart', 'Lagerhold', null);

			if (canRead(repository.commonService.prefix.SparePart) && hasModule.sparePart)
				menu.children.push(menuItem('web-topMenu-sparePart', 'Reservedeler', 'sparePart.list'));

			if (canRead(repository.commonService.prefix.SparePartCounting) && hasModule.inventoryControl)
				menu.children.push(menuItem('web-topMenu-sparePartCounting', 'Varetelling', 'sparePartCounting.list'));

			if (canRead(repository.commonService.prefix.SparePartCountingItem) && hasModule.inventoryControl)
				menu.children.push(menuItem('web-topMenu-sparePartCountingItem', 'Lager avvik', 'sparePartCountingItem.list'));

			if (canRead(repository.commonService.prefix.SparePartWithdrawal) && hasModule.sparePart)
				menu.children.push(menuItem('web-topMenu-sparePartWithdrawal', 'Uttakshistorikk', 'sparePartWithdrawal.list'));

			return menu;
		};

		var createKeyMenu = function () {
			var menu = menuItem('web-topMenu-group-keyManagement', "NøkkelForvaltning");

			if (canRead(repository.commonService.prefix.DoorKeySystem) && hasModule.key)
				menu.children.push(menuItem('web-topMenu-keySystem', 'Nøkkelsystem', 'doorKeySystem.list'));

			if (canRead(repository.commonService.prefix.DoorKey) && hasModule.key)
				menu.children.push(menuItem('web-topMenu-key', 'Nøkkel', 'doorKey.list'));

			if (canRead(repository.commonService.prefix.DoorLock) && hasModule.key)
				menu.children.push(menuItem('web-topMenu-lock', 'Lås', 'doorLock.list'));

			return menu;
		};

		var createMaintenanceMenu = function () {
			var menu = menuItem('web-topMenu-maintenance', 'Vedlikehold', null, 'fa-wrench');

			if (canRead(repository.commonService.prefix.WorkOrder) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-workOrder', 'Arbeidsordre', 'workOrder.list'));

			if (canRead(repository.commonService.prefix.Deviation) && hasModule.deviation)
				menu.children.push(menuItem('web-topMenu-deviation', 'Avvik', 'deviation.list'));

			if (canRead(repository.commonService.prefix.Budget) && hasModule.budget)
				menu.children.push(menuItem('web-topMenu-budget', 'Budsjett', 'budget.list'));

			if (canRead(repository.commonService.prefix.Cost) && (hasModule.budget || hasModule.activity))
				menu.children.push(menuItem('web-topMenu-cost', 'Kostnader', 'cost.list'));

			if (canRead(repository.commonService.prefix.PurchaseOrder) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-purchaseOrder', 'Bestilling', 'purchaseOrder.list'));

			if (canRead(repository.commonService.prefix.PurchaseOrderItem) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-purchaseOrderItem', 'Bestillingsobjekter', 'purchaseOrderItem.list'));

			if (canRead(repository.commonService.prefix.Request) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-request', 'Meldinger', 'request.list'));

			if (canRead(repository.commonService.prefix.OperationalMessage) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-operationalMessage', 'Driftsmeldinger', 'operationalMessage.list'));

			if (canRead(repository.commonService.prefix.PeriodicTask) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-periodicTask', 'Periodiske rutiner', 'periodicTask.list'));

			if (canRead(repository.commonService.prefix.Project) && hasModule.projectManagement)
				menu.children.push(menuItem('web-topMenu-project', 'Prosjekter', 'project.list'));

			var sparePartMenu = createSparePartMenu();
			if (sparePartMenu.children.length) menu.children.push(sparePartMenu);

			if (canRead(repository.commonService.prefix.Condition) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-correctiveAction', 'Tilstand og Tiltak', 'condition.list'));

			if (canRead(repository.commonService.prefix.ControlListXEntity) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-completedControlLists', 'Utfylte kontrollister', 'completedControlLists.list'));

			if (canRead(repository.commonService.prefix.ControlListItemAnswer) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-controlListAnswer', 'Kontrollistesvar', 'controlListItemAnswer.list'));

			if (canRead(repository.commonService.prefix.WorkOrder) && canRead(repository.commonService.prefix.PeriodicTask) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-maintenancePlan', 'Vedlikeholdsplan', 'maintenancePlan'));

			if (hasModule.ifcBimsyncHosting && canRead(repository.commonService.prefix.BcfProject) && hasModule.activity)
				menu.children.push(menuItem('web-topMenu-bcfProject', 'Bcf prosjekter', 'bcfProject.list'));

			return menu;
		};

		var createManagementMenu = function () {
			var menu = menuItem('web-topMenu-management', 'Forvaltning', null, 'fa-folder-bookmark');
			if (canRead(repository.commonService.prefix.Area) && hasModule.housing)
				menu.children.push(menuItem('web-topMenu-housing', 'Bolig', 'housing.list'));

			if (canRead(repository.commonService.prefix.Contract) && hasModule.housing && $localStorage.generalOptions.ShowContractHousingInWeb)
				menu.children.push(menuItem('web-topMenu-housingContract', 'Boligavtaler', 'housingContract.list'));

			if (canRead(repository.commonService.prefix.Customer) && hasModule.housing)
				menu.children.push(menuItem('web-topMenu-housingCustomer', 'Leietaker', 'housingCustomer.list'));

			if (canRead(repository.commonService.prefix.Contract) && (hasModule.internalRent || hasModule.rent || hasModule.invoice) && $localStorage.generalOptions.UseReinvoicing)
				menu.children.push(menuItem('web-topMenu-contract', 'Fakturering', 'reinvoicingContract.list'));

			var keyMenu = createKeyMenu();
			if (keyMenu.children.length) menu.children.push(keyMenu);

			return menu;
		};

		var createCleaningMenu = function () {
			var menu = menuItem('web-topMenu-cleaningMenu', 'Renhold', null, 'fa-vacuum');
			if (canRead(repository.commonService.prefix.Cleaning) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning', 'Renhold', 'cleaning.list'));

			if (canRead(repository.apiData.cleaningQualityControl.prefix) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningQualityControl', 'INSTA-kontroll', 'cleaningQualityControl.list'));

			if (canRead(repository.apiData.cleaningCompletion.prefix) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningCompletion', 'Aktivitetsoversikt', 'cleaningCompletion.list'));

			if (canRead(repository.apiData.cleaningCompletionHistory.prefix) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningCompletion-overview', 'Historikk', 'cleaningCompletion.overview'));

			if (canRead(repository.commonService.prefix.Drawing) && hasModule.area && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning-drawing', 'Tegninger', 'cleaning.dwg.list'));

			if (canRead(repository.commonService.prefix.CleaningTask) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningTask', 'Oppgaveadministrasjon', 'cleaningTask.list'));

			if (canRead(repository.commonService.prefix.Area) && hasModule.area && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning-area', 'Rom', 'cleaning.area.list'));

			if (canRead(repository.commonService.prefix.ResourceGroup) && hasModule.activity && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaning-resourceGroup', 'Renholdsteam', 'cleaning.resourceGroup.list'));

			if (canRead(repository.commonService.prefix.CleaningQuality) && hasModule.cleaningWeb)
				menu.children.push(menuItem('web-topMenu-cleaningQuality', 'Renholdskvalitet', 'cleaningQuality.list'));

			return menu;
		};


		var createMapMenu = function () {
			if (hasModule.map) {
				return menuItem('web-topMenu-map', 'Kart', 'map.view', 'fa-map');
			}
			return null;
		};

		var createAdminMenu = function () {
			if ($scope.userInfo.isSystemAdministrator || $scope.userInfo.isUserAdministrator) {
				return menuItem('web-topMenu-admin', 'Admin', 'admin.dashboard', 'fa-gear');
			}
			return null;
		};

		// Recreate menu for each update
		var updateMenu = function () {
			index = 0;
			var mainMenu = [];
			var basicMenu = createBasicMenu();
			var maintenanceMenu = createMaintenanceMenu();
			var managementMenu = createManagementMenu();
			var cleaningMenu = createCleaningMenu();
			var mapMenu = createMapMenu();
			var adminMenu = createAdminMenu();

			if (basicMenu.children.length)
				mainMenu.push(basicMenu);
			if (maintenanceMenu.children.length)
				mainMenu.push(maintenanceMenu);
			if (managementMenu.children.length)
				mainMenu.push(managementMenu);
			if (cleaningMenu.children.length)
				mainMenu.push(cleaningMenu);
			if (mapMenu)
				mainMenu.push(mapMenu);
			if (adminMenu)
				mainMenu.push(adminMenu);

			$scope.mainMenu = mainMenu;
		};

		updateMenu();

		$scope.clickSidebarItem = function (event, menuItem) {
			if ($scope.mainctrl && $scope.mainctrl.sidebarStat)
				$scope.mainctrl.sidebarStat(event);

			var state = _.find(states.list, function (state) {
				return state.name === menuItem.sref;
			});
			if (state && state.params && state.params.entityData && state.params.entityData.prefix) {
				var prefix = state.params.entityData.prefix;
				if ($localStorage.listParams && $localStorage.listParams[prefix])
					delete $localStorage.listParams[prefix].page;
			}
		};

		$scope.$on($scope.events.updateMenu, function () {
			$scope.guidDataOwner = commonService.getFilterData().selectedDataOwner.Guid;
			$scope.hasImageLogo = !!$localStorage.generalOptions.GuidImageLogo || commonService.getFilterData().selectedDataOwner.GuidImageLogo;
			updateAccess();
		});

	}
})();
