(function () {

	var app = angular.module('Plania',
		[
			'ngStorage',
			'ngSanitize',
			'ngTable',
			'ngAnimate',
			'ngFileUpload',
			'ngTagsInput',
			'ui.bootstrap',
			'ui.bootstrap.contextMenu',
			'ui.tree',
			'ui.router',
			'ui.tinymce',
			'uiCropper',
			'leaflet-directive',
			'angular-loading-bar',
			'localytics.directives',
			'slick',
			'Firestitch.angular-counter',
			'nouislider',
			'internationalPhoneNumber'
		]);

	app.config(['$httpProvider', '$locationProvider', 'cfpLoadingBarProvider', '$tooltipProvider', '$localStorageProvider', '$sessionStorageProvider', '$modalProvider', 'ipnConfig', '$sceDelegateProvider', '$compileProvider', function ($httpProvider, $locationProvider, loadingBarConfig, tooltipProvider, localStorageProvider, sessionStorageProvider, $modalProvider, ipnConfig, $sceDelegateProvider, $compileProvider) {
		$locationProvider.html5Mode(true).hashPrefix('!');
		$httpProvider.interceptors.push('authInterceptorService');
		$httpProvider.interceptors.push('setSavingInterceptorService');
		loadingBarConfig.includeBar = false;

		if ($compileProvider.aHrefSanitizationWhitelist) $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|webcal|local|file|data|blob|plania-app):/);

		//Disable tooltip on touch devices
		var parser = new UAParser();
		var result = parser.getResult();
		var touch = result.device && (result.device.type === 'tablet' || result.device.type === 'mobile');

		if (touch) {
			var options =
			{
				trigger: 'dontTrigger' // default dummy trigger event to show tooltips
			};
			tooltipProvider.options(options);
		}
		//end tooltip settings

		//serializing and deserializing localstorage
		var magicNumber = 42;
		var moveChars = function (key, n) {
			var chars = key.toString().split('');

			for (var i = 0; i < chars.length; i++) {
				var c = chars[i].charCodeAt(0);

				chars[i] = String.fromCharCode(c + n);

			}
			return chars.join('');
		};

		var serializer = function (value) {
			var stringValue = JSON.stringify(value);

			var length = Math.floor(Math.random() * 89) + 10;
			stringValue = moveChars(stringValue, Math.abs(length + magicNumber));
			stringValue = length + stringValue;
			return stringValue;
		};

		var deserializer = function (value) {

			var length = value.slice(0, 2);
			var stringValue = moveChars(value.slice(2, value.length), -Math.abs(Math.abs(length) + magicNumber));
			try {
				return JSON.parse(stringValue);
			} catch (error) {
				return JSON.parse(value);
			}
		};

		localStorageProvider.setSerializer(serializer);
		localStorageProvider.setDeserializer(deserializer);
		sessionStorageProvider.setSerializer(serializer);
		sessionStorageProvider.setDeserializer(deserializer);
		//end serializing settings

		//modal settings
		$modalProvider.options.backdrop = "static";
		//end modal settings

		//international-phone-number settings
		ipnConfig.utilsScript = 'scripts/libphonenumber/utils.js';
		ipnConfig.preferredCountries = ['no', 'dk', 'se'];
		ipnConfig.autoPlaceholder = false;
		//end international-phone-number settings
		$sceDelegateProvider.resourceUrlWhitelist([
			'self',
			'https://www.yr.no/**'
		]);
	}]);

	app.run(['$rootScope', '$state', 'authService', 'TranslationService', 'ModuleService', 'Constants', '$templateCache', 'datepickerConfig', 'datepickerPopupConfig', '$filter', 'config', '$location', 'ListService', '$window', '$localStorage', '$modalStack', 'SignalRconfig', 'SignalR', '$modal', 'DocumentService', main]);

	function main($rootScope, $state, authService, translationService, moduleService, constants, $templateCache, datepickerConfig, datepickerPopupConfig, $filter, config, $location, listService, $window, $localStorage, $modalStack, signalRconfig, signalR, $modal, documentService) {
		$rootScope.navigation = $state;
		$rootScope.isPublicPage = true;
		$rootScope.isLoading = false;
		$rootScope.disableGlobalFiltering = false;
		$rootScope.translationMode = false;
		$rootScope.editPageHasBeenUpdatedMessage = null;

		$rootScope.imageApiUrl = config.baseUrlApi + 'image/';
		$rootScope.backgroundImageApiUrl = $rootScope.imageApiUrl + 'background/';
		$rootScope.logoImageApiUrl = $rootScope.imageApiUrl + 'logo/';

		$rootScope.customization = {
			isCustomer: function (customerId) {
				if ($localStorage.generalOptions)
					return $localStorage.generalOptions.CustomerId === customerId.toString();
				// In case generalOptions is not loaded yet: allow one-time binding by returning undefined, which keeps the ::watch active
				return undefined;
			}
		};

		$rootScope.events = {
			newSelection: 'newDataOwner',
			updateMenu: 'updateMenu',
			updatedAccess: 'updatedAccess',
			userInfoChangedEvent: 'userInfoChangedEvent',
			translationMode: 'translationModeEvent',
			updatedModuleData: 'updatedModuleData'
		};

		// To prevent multiple modals, and to prevent auto closing
		var isAcceptTermsOfUseModalOpen = false;
		var setGlobalUserInfo = function () {
			$rootScope.userInfo = authService.getUserData();
			$rootScope.home = {
				state: authService.getUserData().MenuContext || 'building.list',
				params: { menuGuid: authService.getUserData().GuidWebMenu || null }
			};

			if (!isAcceptTermsOfUseModalOpen && $rootScope.userInfo && $rootScope.userInfo.needToAcceptTermsOfUse) {
				isAcceptTermsOfUseModalOpen = true;
				$modal.open({
					templateUrl: 'app/common/modal/views/acceptTermsOfUseModal.html',
					controller: 'AcceptTermsOfUseModalController',
					size: "md",
					keyboard: false
				}).result.then(function () {
					isAcceptTermsOfUseModalOpen = false;
				}, function () {
					isAcceptTermsOfUseModalOpen = false;
				});
			}
		};

		setGlobalUserInfo();

		$rootScope.$on($rootScope.events.userInfoChangedEvent, function () {
			setGlobalUserInfo();
		});

		if (translationService.getLocale()) {
			translationService.getTranslations();
		}

		$rootScope.dateOptions = constants.dateOptions;

		$rootScope.hasModule = function (module) {
			return moduleService.hasModule(moduleService.moduleFlags[module]);
		};

		$rootScope.hasReadAccess = function (entity, parentEntity, checkOtherDO) {
			if (!checkOtherDO) checkOtherDO = false;
			return authService.hasReadAccess(entity, checkOtherDO, parentEntity);
		};

		$rootScope.hasCreateAccess = function (entity, parentEntity) {
			return authService.hasCreateAccess(entity, parentEntity);
		};

		$rootScope.hasDeleteAccess = function (entity, parentEntity, checkOtherDO) {
			if (!checkOtherDO) checkOtherDO = false;
			return authService.hasDeleteAccess(entity, checkOtherDO, parentEntity);
		};

		$rootScope.hasEditAccess = function (entity, parentEntity, checkOtherDO) {
			if (!checkOtherDO) checkOtherDO = false;
			return authService.hasEditAccess(entity, checkOtherDO, parentEntity);
		};

		$rootScope.go = function (state, params, event) {
			if (event && event.ctrlKey) {
				var url = $state.href(state, params);
				$window.open(url, '_blank');
			} else {
				$state.go(state, params);
			}
		};

		$rootScope.goBack = function (defaultState, defaultParams) {
			if (defaultState && defaultState.indexOf('.edit') >= 0) {
				$state.go(defaultState, defaultParams, { reload: true });
				return;
			}

			$window.history.back();
		};

		$rootScope.getEntityCaption = function (entity) {
			if (!entity) return '';

			var id = entity.Id || entity.id || '';
			var description = entity.Description || entity.description || '';

			if (!id)
				return description;
			return id + (description ? ' - ' + description : '');
		};

		$rootScope.$on('$stateChangeStart', function (event, to, toParams) {
			var top = $modalStack.getTop();
			if (top && !isAcceptTermsOfUseModalOpen) {
				$modalStack.dismiss(top.key);
			}

			if (!to.publicPage && !authService.getUserData().isAuthenticated) {
				event.preventDefault();
				var returnTo = JSON.stringify({ name: to.name, params: toParams });
				$state.go('login', { returnToState: returnTo, message: translationService.translate('web-notLoggedIn-Navigation-message', 'Du har ikke en aktiv innlogging, logg inn for å komme til ønsket side.') });
			}
		});

		var setHtmlTitleFromParams = function (to, toParams) {
			// web-topMenu is specified in PLANIA_TRANSLATE.JS parseConfigRoute(), and to.name is fallback value if not translated
			// web-topMenu-workorder.list
			var translatedStateName = translationService.getTranslationIfExists('web-topMenu-' + to.name, '');
			if (translatedStateName && translatedStateName !== to.name) {
				$rootScope.setHtmlTitleByText(translatedStateName);
				return;
			}

			if (toParams.entityData) {
				// web-topMenu-workorder
				var translatedMenuItem = translationService.getTranslationIfExists('web-topMenu-' + toParams.entityData.prefix);
				if (translatedMenuItem) {
					$rootScope.setHtmlTitleByText(translatedMenuItem);
					return;
				}
			}

			// Plania - workorder.list
			$rootScope.setHtmlTitleByText("Plania - " + to.name);
		};

		$rootScope.setHtmlTitleByText = function (title) {
			$rootScope.htmlTitle = title;
		};

		$rootScope.setHtmlTitleByModel = function (model) {
			$rootScope.setHtmlTitleByText(model ? model.Caption : '');
		};

		$rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from, fromParams) {
			window.scrollTo(0, 0);

			if (from.name && from.name !== to.name && from.excludeFromHistory) {
				// avoid previous page in history, replace by the new one https://stackoverflow.com/questions/12832317/history-replacestate-example
				$window.history.replaceState({}, 'any', $location.absUrl());
			}

			$rootScope.isPublicPage = to.publicPage;

			if (to.name.indexOf('.edit') > -1) {
				$rootScope.disableGlobalFiltering = true;
			} else {
				$rootScope.disableGlobalFiltering = false;
			}

			if (authService.getUserData().isAuthenticated) {
				signalRconfig.setEditPageListener(to, toParams, from, fromParams);
			}

			setHtmlTitleFromParams(to, toParams);
		});

		if ($window.history.scrollRestoration) {
			$window.history.scrollRestoration = 'manual';
		}

		if (authService.getUserData().isAuthenticated) {
			signalR.setQs({
				fingerprint: config.fingerprint,
				guidUser: authService.getUserData().guidUser
			});

			signalR.start();
		}

		/*
			exception: {
				type: string;
				value: string; // Exception value
				stacktrace: { frames: frame[] }
				mechanism: {
					handled: boolean;
					type: string
				}
			}

			frame: {
				colno: number;
				filename: string;
				function: string;
				in_app: boolean;
				lineno: number;
			}
		*/

		var translationModeListener = function (event) {
			if (event.key === "l" && (event.ctrlKey || event.metaKey) && event.altKey) {
				if ($rootScope.hasEditAccess('LanguageXWebText') && $rootScope.userInfo.isSystemAdministrator) {
					$rootScope.$apply(function () {
						$rootScope.translationMode = !$rootScope.translationMode;
						$rootScope.$broadcast($rootScope.events.translationMode);
					});
				}
			}
		};

		var pasteUserNameTimestampShortcut = function (event) {
			// Helper to find active element through current document or shadow root.
			function getActiveElement(root) {
				if (!root) root = document;
				var activeEl = root.activeElement;
				if (!activeEl) return null;
				return activeEl.shadowRoot ? getActiveElement(activeEl.shadowRoot) : activeEl;
			}

			var activeElement = getActiveElement();
			if (!activeElement || !(event.ctrlKey && event.key === "<")) return;

			if (activeElement.tagName.toLowerCase() === "input" || activeElement.tagName.toLowerCase() === "textarea") {
				var text = $localStorage.userData.realName;
				if (!$localStorage.userData.realName)
					text = $localStorage.userData.userName;

				text += " - ";
				text += $filter('date')(new Date().toISOString(), 'dd.MM.yyyy HH:mm:ss');

				var selectionStart = activeElement.selectionStart;
				var currentText = activeElement.value;
				var newCaretPos = selectionStart + text.length;

				// For React (and works with default) to trigger change.
				var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
					activeElement.tagName.toLowerCase() === "textarea" ? window.HTMLTextAreaElement.prototype : window.HTMLInputElement.prototype, 'value').set;

				// When start and end is not same, then that indicates that the text is highlighted. Remove highlighted, which will later use selectionStart to pase new text.
				if (activeElement.selectionStart !== activeElement.selectionEnd) {
					currentText = currentText.substring(0, activeElement.selectionStart) + currentText.substring(activeElement.selectionEnd);
				}

				// Seems like we need to manually limit the text, so if there exists a maxlength we need to remove the parts.
				if (activeElement.maxLength > 0) {
					var availableLength = activeElement.maxLength - currentText.length;
					if (availableLength <= 0) return;
					text = text.substring(0, availableLength);
				}

				var newText = currentText.substring(0, selectionStart) + text + currentText.substring(selectionStart);

				nativeInputValueSetter.call(activeElement, newText);
				activeElement.dispatchEvent(new Event('input', { bubbles: true }));

				// Move to end of pasted text.
				activeElement.setSelectionRange(newCaretPos, newCaretPos);
			}
		};

		document.addEventListener("keydown", translationModeListener);
		document.addEventListener("keydown", pasteUserNameTimestampShortcut);

		datepickerConfig.showWeeks = false;
		datepickerPopupConfig.showButtonBar = false;

		//Caching templates
		$templateCache.put('template/datepicker/day.html',
			"<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\" class=\"dp-table dpt-day\"><thead><tr class=\"tr-dpnav\"><th><button type=\"button\" class=\"pull-left btn-dp\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"fas fa-arrow-left-long\"></i></button></th><th colspan=\"{{::5 + showWeeks}}\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" class=\"w-100 btn-dp\"><div class=\"dp-title\">{{title}}</div></button></th><th><button type=\"button\" class=\"pull-right btn-dp\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"fas fa-arrow-right-long\"></i></button></th></tr><tr class=\"tr-dpday\"><th ng-if=\"showWeeks\" class=\"text-center\"></th><th ng-repeat=\"label in ::labels track by $index\" class=\"text-center\"><small aria-label=\"{{::label.full}}\">{{::label.abbr}}</small></th></tr></thead><tbody><tr ng-repeat=\"row in rows track by $index\"><td ng-if=\"showWeeks\" class=\"text-center h6\"><em>{{ weekNumbers[$index] }}</em></td><td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\" ng-class=\"::dt.customClass\"><button type=\"button\" class=\"w-100 btn-dp btn-dpday btn-dpbody\" ng-class=\"{'dp-today': dt.current, 'dp-selected': dt.selected, 'dp-active': isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span ng-class=\"::{'dp-day-muted': dt.secondary, 'dp-day-today': dt.current}\">{{::dt.label}}</span></button></td></tr></tbody></table>"
		);

		$templateCache.put('template/datepicker/month.html',
			"<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\" class=\"dp-table\"><thead><tr class=\"tr-dpnav\"><th><button type=\"button\" class=\"pull-left btn-dp\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"fas fa-arrow-left-long\"></i></button></th><th><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\" class=\"w-100 btn-dp\"><div class=\"dp-title\">{{title}}</div></button></th><th><button type=\"button\" class=\"pull-right btn-dp\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"fas fa-arrow-right-long\"></i></button></th></tr></thead><tbody><tr ng-repeat=\"row in rows track by $index\"><td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\" ng-class=\"::dt.customClass\"><button type=\"button\" class=\"w-100 btn-dp btn-dpbody\" ng-class=\"{'dp-selected': dt.selected, 'dp-active': isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span ng-class=\"::{'dp-day-today': dt.current}\">{{::dt.label}}</span></button></td></tr></tbody></table>"
		);

		$templateCache.put('template/datepicker/year.html',
			"<table role=\"grid\" aria-labelledby=\"{{::uniqueId}}-title\" aria-activedescendant=\"{{activeDateId}}\" class=\"dp-table\"><thead><tr class=\"tr-dpnav\"><th><button type=\"button\" class=\"pull-left btn-dp\" ng-click=\"move(-1)\" tabindex=\"-1\"><i class=\"fas fa-arrow-left-long\"></i></button></th><th colspan=\"3\"><button id=\"{{::uniqueId}}-title\" role=\"heading\" aria-live=\"assertive\" aria-atomic=\"true\" type=\"button\" class=\"w-100 btn-dp\" ng-click=\"toggleMode()\" ng-disabled=\"datepickerMode === maxMode\" tabindex=\"-1\"><div class=\"dp-title\">{{title}}</div></button></th><th><button type=\"button\" class=\"pull-right btn-dp\" ng-click=\"move(1)\" tabindex=\"-1\"><i class=\"fas fa-arrow-right-long\"></i></button></th></tr></thead><tbody><tr ng-repeat=\"row in rows track by $index\"><td ng-repeat=\"dt in row track by dt.date\" class=\"text-center\" role=\"gridcell\" id=\"{{::dt.uid}}\"><button type=\"button\" class=\"w-100 btn-dp btn-dpbody\" ng-class=\"{'dp-selected': dt.selected, 'dp-active': isActive(dt)}\" ng-click=\"select(dt.date)\" ng-disabled=\"dt.disabled\" tabindex=\"-1\"><span ng-class=\"::{'dp-day-today': dt.current}\">{{::dt.label}}</span></button></td></tr></tbody></table>"
		);

		$templateCache.put('template/tabs/tabset.html',
			"<div class=\"clearfix\"><ul class=\"tab-nav\" ng-class=\"{'tn-vertical': vertical, 'tn-justified': justified, 'tab-nav-right': right}\" ng-transclude></ul><div class=\"tab-content\"><div class=\"tab-pane\" ng-repeat=\"tab in tabs\" ng-class=\"{active: tab.active}\" tab-content-transclude=\"tab\"></div></div></div>"
		);

		$templateCache.put('template/carousel/carousel.html',
			"<div ng-mouseenter=\"pause()\" ng-mouseleave=\"play()\" class=\"carousel\" ng-swipe-right=\"prev()\" ng-swipe-left=\"next()\"><ol class=\"carousel-indicators\" ng-show=\"slides.length > 1\"><li ng-repeat=\"slide in slides | orderBy:'index' track by $index\" ng-class=\"{active: isActive(slide)}\" ng-click=\"select(slide)\"></li></ol><div class=\"carousel-inner\" ng-transclude></div><span class=\"left carousel-control\" ng-click=\"prev()\" ng-show=\"slides.length > 1\"><span class=\"fas fa-chevron-left\"></span></span> <span class=\"right carousel-control\" ng-click=\"next()\" ng-show=\"slides.length > 1\"><span class=\"fas fa-chevron-right\"></span></a></div>"
		);

		// Variable to check if angular has been loaded
		window.isAngularLoaded = true;
	}
})();
